const FORCED_COLORS_MAP = new Map<string, { background: string; text: string }>(
  [
    ["Interview", { background: "#B8EDFF", text: "#06354B" }],
    ["Micro-learning", { background: "#DFD8FC", text: "#311461" }],
  ],
);

export function useBlogTagColors(tag: string = "") {
  const match = FORCED_COLORS_MAP.get(tag);

  if (match) return match;

  const background = useStringToColor(tag ?? "", {
    saturation: 85,
    luminance: 85,
  });

  const text = useStringToColor(tag ?? "", {
    saturation: 90,
    luminance: 20,
  });

  return {
    background: background.value,
    text: text.value,
  };
}
